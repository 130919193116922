<script setup>
import { computed } from 'vue'
import Navigation from '~/components/Navigation.vue'
import { useNavigationStore } from '~/store/navigation.js'
import { Close } from '@element-plus/icons-vue'
import { useQuotesStore } from '~/store/quotes.js'
import { useDayjs } from '#dayjs'
import SentryFeedbackButtonHorizontal from './SentryFeedbackButtonHorizontal.vue'

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
})
const navigationStore = useNavigationStore()
const quoteLeadsStore = useQuotesStore()

const isExpanded = computed(() => navigationStore.isExpanded)

const dayjs = useDayjs()

const logo = computed(() => (isExpanded.value ? '/images/avrillo-logo.png' : '/images/favicon.png'))

const mobileOpen = computed(() => navigationStore.mobileOpen)
// eslint-disable-next-line vue/return-in-computed-property
const getPendingSoldQuotesBadge = computed(() => {
  return `
  <div class="relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full top-0 -end-1 dark:border-gray-900">${quoteLeadsStore.quoteStatsRequests?.sold_pending ?? 0}</div>
  `
})
// eslint-disable-next-line vue/return-in-computed-property
const getPendingPreSoldQuotesBadge = computed(() => {
  return `
  <div class="relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full top-0 -end-1 dark:border-gray-900">${quoteLeadsStore.quoteStatsRequests?.presold_pending ?? 0}</div>
  `
})

// eslint-disable-next-line vue/return-in-computed-property
const getInDraftSoldQuotesBadge = computed(() => {
  return `
  <div class="relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-amber-500 border-2 border-white rounded-full -top-0.5 -end-1 dark:border-gray-900">${quoteLeadsStore.quoteStatsRequests?.sold_draft ?? 0}</div>
  `
})
// eslint-disable-next-line vue/return-in-computed-property
const getInDraftPreSoldQuotesBadge = computed(() => {
  return `
  <div class="relative inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-amber-500 border-2 border-white rounded-full -top-0.5 -end-1 dark:border-gray-900">${quoteLeadsStore.quoteStatsRequests?.presold_draft ?? 0}</div>
  `
})

const startOfCurrentMonth = dayjs('2024-06-01')

const defaultFilters = computed(() => {
  return `?filters[status][values][0]=1&filters[status][values][1]=3&filters[status][filterType]=set&filters[status][columnKey]=status&filters[created_at][dateFrom]=${startOfCurrentMonth.format('YYYY-MM-DD')}&filters[created_at][dateTo]=&filters[created_at][filterType]=date&filters[created_at][type]=greaterThan&filters[created_at][columnKey]=created_at`
})

const defaultCallLogFilters = computed(() => {
  return `?page=1&perPage=30&filters[sold_or_not_code][values][0]=S&filters[sold_or_not_code][filterType]=set&filters[sold_or_not_code][columnKey]=sold_or_not_code`
})

const startOfMonth = dayjs().startOf('month')
const today = dayjs()

const defaultFiltersStats = computed(() => {
  return `?page=1&perPage=30&filters[sold_or_not_code][values][0]=S&filters[sold_or_not_code][filterType]=set&filters[sold_or_not_code][columnKey]=sold_or_not_code&start_date=${startOfMonth.format('YYYY-MM-DD')}&end_date=${today.format('YYYY-MM-DD')}`
})

const defaultFiltersListQuotes = computed(() => {
  return `?page=1&perPage=30&sort[quote_date_with_created]=desc`
})

const navigation = computed(() => {
  return [
    {
      title: 'Quote Cases',
      icon: ElIconChatLineSquare,
      visible: true,
      children: [
        { title: 'Give a Quote', href: '/quote-cases/create', visible: true, icon: ElIconChatLineRound },
        { title: 'List Quotes', href: `/quote-cases${defaultFiltersListQuotes.value}`, visible: true, icon: ElIconList },
        { title: 'Draft Quotes', href: '/quote-cases/drafts', visible: true, icon: ElIconList },
        { title: 'Call Logs', href: `/quote-cases/call-logs${defaultCallLogFilters.value}`, visible: true, icon: ElIconPhone },
        { title: 'Call Status', href: `/quote-cases/call-status`, visible: true, icon: ElIconPhoneFilled },
        { title: 'Date Logs', href: `/quote-cases/logs`, visible: true, icon: ElIconCalendar },
      ],
    },
    {
      title: 'Sale Ready Pack',
      icon: ElIconDocumentCopy,
      visible: false,
      children: [
        { title: 'List Packs', href: '/sale-ready-pack', visible: true, icon: ElIconList },
        { title: 'New Demo Pack', href: '/sale-ready-pack/create', visible: true, icon: ElIconDocumentAdd },
      ],
    },
    {
      title: 'Quote Leads',
      icon: ElIconChatLineSquare,
      visible: true,
      children: [
        {
          title: `Sold Leads ${getPendingSoldQuotesBadge.value} ${getInDraftSoldQuotesBadge.value}`,
          href: `/quote-leads/sold${defaultFilters.value}`,
          visible: true,
          icon: ElIconList,
        },
        {
          title: `PreSold Leads ${getPendingPreSoldQuotesBadge.value} ${getInDraftPreSoldQuotesBadge.value}`,
          href: `/quote-leads/presold${defaultFilters.value}`,
          visible: true,
          icon: ElIconDocumentAdd,
        },
      ],
    },
    {
      title: 'Reports',
      icon: ElIconFiles,
      visible: true,
      children: [
        {
          title: `Completion Report`,
          href: '/reports/completion-target-report',
          visible: true,
          icon: ElIconPieChart,
        },
        {
          title: `Performance Report`,
          href: '/reports/performance-report',
          visible: true,
          icon: ElIconFlag,
        },
      ],
    },
    {
      title: 'Quote Stats',
      icon: ElIconTrendCharts,
      href: `/quote-cases/stats${defaultFiltersStats.value}`,
      visible: true,
    },
    {
      title: 'Quote Giver Stats',
      icon: ElIconTrendCharts,
      href: '/quote-cases/quote-monthly-giver-stats',
      visible: true,
    },
    {
      title: 'Memorandum of Sale',
      icon: ElIconList,
      href: '/sale-memos',
      visible: true,
    },
    {
      title: 'Replies to Requisitions',
      icon: ElIconList,
      href: '/requisition-replies',
      visible: true,
    },
  ]
})

function toggleHoverExpanded(isExpanded) {
  navigationStore.toggleHoverExpanded(isExpanded)
}

function toggleExpanded() {
  navigationStore.toggleExpanded()
}
</script>

<template>
  <aside
    :class="isExpanded ? 'w-64 px-4' : 'w-20 px-3'"
    class="hidden h-full flex-col gap-6 overflow-auto border-r bg-white py-6 text-sm shadow transition-all duration-300 ease-in-out lg:flex"
    @mouseenter="toggleHoverExpanded(true)"
    @mouseleave="toggleHoverExpanded(false)"
  >
    <header class="flex items-center gap-3">
      <NuxtLink to="/">
        <img :src="logo" :class="isExpanded ? 'h-10' : 'mx-auto h-8'" class="!w-auto flex-shrink-0 cursor-pointer" />
      </NuxtLink>

      <el-button
        :icon="navigationStore.expanded ? ElIconCircleClose : ElIconCirclePlus"
        :class="(isExpanded ? '' : 'hidden ') + (navigationStore.expanded ? 'bg-primary text-white' : 'text-primary')"
        class="ml-auto border-primary"
        circle
        @click="toggleExpanded"
      />
    </header>

    <Navigation :navigation />
  </aside>

  <div
    :class="mobileOpen ? 'opacity-50' : 'invisible opacity-0'"
    class="fixed left-0 top-0 z-10 h-full w-full bg-black transition-all duration-300 lg:hidden"
    @click="navigationStore.closeMobile"
  />
  <aside
    :class="mobileOpen ? 'left-0' : '-left-80'"
    class="fixed z-20 flex h-full w-80 flex-col gap-6 overflow-auto border-r bg-white px-4 py-6 text-sm shadow transition-all duration-300 ease-in-out lg:hidden"
  >
    <header class="flex items-center gap-1">
      <NuxtLink to="/">
        <img src="/images/avrillo-logo.png" class="h-10 flex-shrink-0 cursor-pointer" />
      </NuxtLink>

      <el-icon class="ml-auto text-gray-500" size="20" @click="navigationStore.closeMobile">
        <Close />
      </el-icon>
    </header>

    <Navigation :navigation />
    <div class="sentry-container">
      <SentryFeedbackButtonHorizontal class="max-w-full whitespace-normal leading-5" :user="user" />
    </div>
  </aside>
</template>
